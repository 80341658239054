<script>
import Modal from '../outlets/Modal.vue';
import PricingBotEmptyState from './PricingBotEmptyState.vue';
import PricingBotMessage from './PricingBotMessage.vue';
import analitycsMixins from '../../mixins/analyticsMixins';

export default {
  components: { PricingBotMessage, PricingBotEmptyState, Modal },
  mixins: [analitycsMixins],
  props: {
    value: { type: String, required: true },
    sessionId: { type: String, default: null },
    closable: { type: Boolean, default: true },
    onClose: { type: Function, default: null },
    showMessageContainsMobileApp: { type: Boolean, default: false },
  },
  data() {
    return {
      messages: [],
      loading: false,
      initialMessage: false,
      showGreeting: false,
      idleTimer: null,
      idleTimerMessage: false,
      showIdleMessage: false,
      connectToSpecialts: false,
    };
  },
  computed: {
    isInitialState() {
      return !this.messages.length;
    },
  },
  mounted() {
    setTimeout(() => {
      this.initialMessage = true;
      this.showGreeting = true;
    }, 3000);
    this.initializeIdleTimer();

    window.washyModalOpened = true;
    if (window.washyModalOpened) {
      window.Intercom('boot');

      if (window.Intercom && typeof window.Intercom === 'function') {
        window.Intercom('hide');
      }
    }

    try {
      const lastConversation = JSON.parse(sessionStorage.getItem('lastConversation') || '') || null;
      if (lastConversation) {
        this.initialMessage = true;
        this.showGreeting = true;
        this.messages = lastConversation.messages || [];
        this.$emit('sessionIdUpdate', lastConversation.chatUuid);
      }
    } catch (e) {
      return;
    }
  },
  methods: {
    onMessageSend(message) {
      this.messages.push({
        content: message,
        role: 'user',
      });

      this.callAssistant(message);

      this.idleTimerMessage = false;
      this.showIdleMessage = false;
      this.resetIdleTimer();
    },
    async callAssistant(message) {
      this.loading = true;

      try {
        const response = await fetch(window.washyChatUrl, {
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
          },
          method: 'POST',
          body: JSON.stringify({
            chatUuid: this.sessionId,
            message: message,
            serviceCountryCode: window.serviceCountryCode,
            startSource: 'landing',
            serviceCountryUrl: window.serviceCountryUrl,
          }),
        });

        const responseData = await response.json();

        if (
          responseData.status === 'OK' &&
          responseData.chatUuid &&
          responseData.conversation?.length
        ) {
          this.$emit('sessionIdUpdate', responseData.chatUuid);
          this.messages = responseData.conversation;
          sessionStorage.setItem(
            'lastConversation',
            JSON.stringify({ messages: this.messages, chatUuid: responseData.chatUuid })
          );
          this.$emit('newMessage');
          this.resetIdleTimer();
        } else {
          window.Sentry?.captureMessage('Error in Washy response', {
            extra: {
              response: responseData,
            },
          });
        }
      } catch (e) {
        window.Sentry?.captureException(e);
      } finally {
        this.loading = false;
      }
    },

    openSupport() {
      const expires = new Date(Date.now() + 30 * 60 * 60 * 1000);
      document.cookie = `intercomButtonState=true; expires=${expires.toUTCString()}; path=/`;

      window.IntercomOpened = true;
      const washyIntercomButton = document.getElementById('washy-facade-btn');

      if (window.IntercomOpened && washyIntercomButton) {
        washyIntercomButton.style.display = 'none';
      }

      if (this.closable) {
        this.close();
      }
    },

    initializeIdleTimer() {
      this.idleTimer = setTimeout(() => {
        clearTimeout(this.idleTimer);
        this.idleTimerMessage = true;
        setTimeout(() => {
          this.$nextTick(() => {
            this.showIdleMessage = true;
          });
        }, 1000);
      }, 300000);
    },

    resetIdleTimer() {
      if (this.idleTimer) {
        clearTimeout(this.idleTimer);
        this.initializeIdleTimer();
      }
    },

    close() {
      if (!this.closable) {
        return;
      }

      if (!this.stacked) {
        document.body.classList.remove('lock-scroll');
      }
      this.onClose ? this.onClose(this.id) : null;
    },
  },
};
</script>

<template>
  <div class="d-flex flex-column gap-16 w-100 pb-1">
    <template v-if="isInitialState">
      <div class="py-64">
        <PricingBotEmptyState />
      </div>
    </template>
    <template>
      <PricingBotMessage
        :initial-message="initialMessage"
        :show-greeting="showGreeting"
        :show-message-contains-mobile-app="showMessageContainsMobileApp"
        loading-role="assistant"
      />

      <PricingBotMessage
        v-for="(message, index) in messages"
        :key="`message-${message.role}-${index}`"
        :message="message"
        @open-support-request="openSupport"
      />

      <PricingBotMessage v-if="loading" loading-role="assistant" />

      <PricingBotMessage
        v-if="idleTimerMessage"
        :idle-timer-message="idleTimerMessage"
        :show-idle-message="showIdleMessage"
        loading-role="assistant"
      />
    </template>
  </div>
</template>
