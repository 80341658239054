<script>
import Modal from './Modal.vue';
import PricingBot from '../pricing_bot/PricingBot.vue';
import TextInput from '../TextInput.vue';

export default {
  components: { TextInput, PricingBot, Modal },
  props: {
    id: { type: String, required: true },
    data: { type: Object, required: true },
  },
  data() {
    return {
      message: '',
      sessionId: null,
      showHelpPicker: true,
      showMessageContainsMobileApp: false,
      showInfoMessage: true,
    };
  },
  computed: {
    shouldShowHelpPicker() {
      return !!this.message?.toLowerCase()?.includes('specialist team');
    },
  },
  created() {
    if (
      sessionStorage.getItem('wasMessageInfoClicked') === 'true' ||
      sessionStorage.getItem('lastConversation')
    ) {
      this.showInfoMessage = false;
    }
  },
  methods: {
    onMessageSend() {
      this.hideMessageInfoBox();
      this.$refs.pricingBot?.onMessageSend(this.message);
      this.message = '';

      setTimeout(() => {
        this.$refs.modal?.scrollToBottom();
      }, 250);
    },
    onNewMessage() {
      setTimeout(() => {
        this.$refs.modal?.scrollToBottom();
      }, 250);
    },

    onSessionIdUpdate(sessionId) {
      this.sessionId = sessionId;
    },
    onTextAreaKeyPress(key) {
      if (!this.message || this.message.trim() === '') {
        return;
      }

      if (key === 'enter') {
        this.$refs.messageInput?.blur();
        this.onMessageSend();
      }
    },
    checkMessageInput() {
      const input = this.$el.querySelector('#message');
      let previousValue = input.value;
      let initialCheck = true;

      const checkValue = () => {
        const currentValue = input.value;
        if (initialCheck || currentValue !== previousValue) {
          this.message = currentValue;
          previousValue = currentValue;
          initialCheck = false;
          setTimeout(checkValue, 200);
        }
      };

      checkValue();
    },
    handleClickOurMobileApp() {
      this.showMessageContainsMobileApp = true;
      this.showInfoMessage = false;
      sessionStorage.setItem('wasMessageInfoClicked', 'true');
    },
    hideMessageInfoBox() {
      this.showInfoMessage = false;
    },
  },
  mounted() {
    this.checkMessageInput();
  },
};
</script>

<template>
  <Modal
    :onClose="() => $emit('close', id)"
    noPaddingFooter
    :id="id"
    ref="modal"
    class="bot-modal-wrapper"
    :disableBackdropClose="true"
    :hideScrollbar="true"
  >
    <template #head>
      <p class="text-center">
        {{ $t('bot.title') }}
      </p>
    </template>
    <PricingBot
      ref="pricingBot"
      v-model="message"
      :session-id="sessionId"
      :onClose="() => $emit('close', id)"
      :show-message-contains-mobile-app="showMessageContainsMobileApp"
      @newMessage="onNewMessage"
      @sessionIdUpdate="onSessionIdUpdate"
    />

    <template #foot class="bot-modal-bottom modal-footer">
      <div
        v-if="showInfoMessage"
        class="pricing-bot-info-box bg-background-grey py-8 px-16 d-flex align-items-center gap-8"
      >
        <img
          :src="require('images/price_bot/light-bulb.svg')"
          :alt="$t('bot.upsell.image_alt')"
          height="24"
          width="24"
        />
        <span
          >{{ $t('bot.forExistingOrders') }}
          <a @click.prevent.stop="handleClickOurMobileApp" class="text-underline clickable">{{
            $t('bot.ourMobileApp')
          }}</a></span
        >

        <img
          :src="require('images/price_bot/closeGrey.svg')"
          class="ms-auto clickable"
          :alt="$t('bot.upsell.image_alt')"
          @click="hideMessageInfoBox"
          height="12"
          width="12"
        />
      </div>
      <div class="pricing-bot-message-wrapper">
        <TextInput
          v-model="message"
          name="message"
          class="d-block pricing-bot-text-input"
          ref="messageInput"
          :label="''"
          :placeholder="$t('bot.askUsAnything')"
          @keyUp="onTextAreaKeyPress"
        />

        <img
          :src="
            !message || message.trim() === ''
              ? require('images/price_bot/chatButtonDisabled.svg')
              : require('images/price_bot/chatButton.svg')
          "
          class="pricing-bot-message-button button p-0"
          :class="{ disabled: !message }"
          width="32"
          height="32"
          @click="onMessageSend"
        />
      </div>
    </template>
  </Modal>
</template>
